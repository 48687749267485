<template>
  <main class="Main">
    <slot />

    <ModalsContainer />
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ModalsContainer } from 'vue-final-modal';

export default defineComponent({
  components: {
    ModalsContainer,
  },
  head() {
    return {
      meta: [
        { hid: 'description', name: 'description', content: '期間限定のおトクなクーポン配信中！' },
        { hid: 'og:title', property: 'og:title', content: '「メルカリ」アプリを開いて「メルペイ」をチェック' },
        { hid: 'og:description', property: 'og:description', content: '期間限定のおトクなクーポン配信中！' },
        { hid: 'og:type', property: 'og:type', content: 'website' },
        { hid: 'og:site_name', property: 'og:site_name', content: 'メルペイクーポン' },
        { hid: 'og:url', property: 'og:url', content: 'https://coupon.merpay.com/' },
        { hid: 'og:image', property: 'og:image', content: require('~/static/img/icon/ogp.png') },
        { hid: 'twitter:site', name: 'twitter:site', content: '@merpay_official' },
        { hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' },
        { hid: 'twitter:title', name: 'twitter:title', content: '「メルカリ」アプリを開いて「メルペイ」をチェック' },
        { hid: 'twitter:description', name: 'twitter:description', content: '期間限定のおトクなクーポン配信中！' },
        { hid: 'twitter:image', name: 'twitter:image', content: require('~/static/img/icon/ogp.png') },
        { hid: 'supported-color-schemes', name: 'supported-color-schemes', content: '' },
        { hid: 'color-scheme', name: 'color-scheme', content: '' },
      ],
    };
  },
});
</script>

<style scoped>
.Main {
  max-width: 600px;
  margin: 0 auto;
}
</style>
